import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Zap, Flame } from 'lucide-react';
import { Integration } from './types/integration';

interface IntegrationRequestCardProps {
  integration: Integration;
}

const IntegrationRequestCard: React.FC<IntegrationRequestCardProps> = ({ integration }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getDemandIndicator = (demand: number) => {
    if (demand >= 120) return Array(3).fill(<Flame className="text-red-500" size={16} />);
    if (demand >= 80) return Array(2).fill(<Flame className="text-orange-500" size={16} />);
    if (demand >= 40) return [<Flame className="text-yellow-500" size={16} />];
    return [<Zap className="text-blue-500" size={16} />];
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow">
      <div 
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-4">
          <h3 className="text-lg font-semibold">{integration.name}</h3>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            {getDemandIndicator(integration.demand).map((icon, index) => (
              <span key={index}>{icon}</span>
            ))}
          </div>
          {isExpanded ? (
            <ChevronUp className="text-gray-400" size={20} />
          ) : (
            <ChevronDown className="text-gray-400" size={20} />
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="mt-4 space-y-3 pl-4 border-l-2 border-gray-100">
          {integration.useCases.map((useCase, index) => (
            <div key={index} className="space-y-1">
              <h4 className="font-medium text-gray-800">{useCase.title}</h4>
              <p className="text-sm text-gray-600">{useCase.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default IntegrationRequestCard;