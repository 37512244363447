import React from 'react';
import IntegrationRequestCard from './IntegrationRequestCard';
import integrationData from './data/integrationRequests.json';
import { Integration } from './types/integration';
import { Flame } from 'lucide-react';

const IntegrationRequests: React.FC = () => {
  const sortedIntegrations = [...integrationData.integrations].sort((a, b) => b.demand - a.demand);

  return (
    <section className="mb-12">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Integration Requests</h2>
        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <div className="flex items-center space-x-1">
            <Flame className="text-red-500" size={16} />
            <Flame className="text-red-500" size={16} />
            <Flame className="text-red-500" size={16} />
            <span>High Demand</span>
          </div>
          <span className="text-gray-400">|</span>
          <div className="flex items-center space-x-1">
            <Flame className="text-yellow-500" size={16} />
            <span>Growing Interest</span>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        {sortedIntegrations.map((integration: Integration) => (
          <IntegrationRequestCard
            key={integration.name}
            integration={integration}
          />
        ))}
      </div>
    </section>
  );
};

export default IntegrationRequests;