import React from 'react'
import { Link } from 'react-router-dom'
import { Home, LayoutDashboard, Monitor, Layers, Link as LinkIcon, Globe, Map, MessageSquare, Settings, LogOut, Linkedin } from 'lucide-react'
import { useAuth, ContextHolder, AdminPortal } from '@frontegg/react'

const Sidebar: React.FC = () => {
  const { user } = useAuth()

  const handleLogout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`
  }

  const handleSettingsClick = () => {
    AdminPortal.show()
  }

  return (
    <div className="bg-primary-400 text-white w-64 min-h-screen p-4 flex flex-col">
      <div className="mb-8">
        <img 
          src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/666c3d6f5a0ea57d121a1846_Logo.webp" 
          alt="FixFinder Logo" 
          className="w-32 mx-auto"
        />
      </div>
      <nav className="flex-grow">
        <Link to="/" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <Home className="mr-3" size={20} /> Home
        </Link>
        <Link to="/desktop" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <Monitor className="mr-3" size={20} /> Desktop
        </Link>
        <Link to="/solutions" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <Layers className="mr-3" size={20} /> Solutions
        </Link>
        <Link to="/integrations" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <LinkIcon className="mr-3" size={20} /> Integrations
        </Link>
        <Link to="/portal" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <Globe className="mr-3" size={20} /> Portal
        </Link>
        <Link to="/reporting" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <LayoutDashboard className="mr-3" size={20} /> Reporting
        </Link>
        <Link to="/roadmap" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <Map className="mr-3" size={20} /> Roadmap
        </Link>
        <Link to="/feedback" className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded">
          <MessageSquare className="mr-3" size={20} /> Feedback
        </Link>
        <button
          onClick={handleSettingsClick}
          className="w-full flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded"
        >
          <Settings className="mr-3" size={20} /> Settings
        </button>
      </nav>
      <div className="mt-auto">
        <button
          onClick={handleLogout}
          className="flex items-center py-2 px-4 text-white hover:bg-primary-300 rounded w-full mb-4"
        >
          <LogOut className="mr-3" size={20} /> Logout
        </button>
        {user && (
          <div className="flex flex-col space-y-6 border-t border-primary-300 pt-4">
            <p className="text-sm">{user.email}</p>
            <a
              href="https://www.linkedin.com/company/fixfinder-saas/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-white hover:text-blue-300 transition-colors"
            >
              <Linkedin size={16} className="mr-2" />
              Follow
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default Sidebar