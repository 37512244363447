import React, { useState, useEffect } from 'react';
import { CheckCircle, Circle } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import { getProgress, updateProgress, updateChecks } from '../../services/nocodbService';
import AutomationService from '../solutions/AutomationService';
import ExpertPath from '../solutions/ExpertPath';
import MasterFixDatabase from '../solutions/MasterFixDatabase';
import OnboardingNavigator from '../solutions/OnboardingNavigator';
import OutageHub from '../solutions/OutageHub';
import ProjectPath from '../solutions/ProjectPath';
import RoboticProcessAutomation from '../solutions/RoboticProcessAutomation';
import WorkflowOrchestrator from '../solutions/WorkflowOrchestrator';
import SolutionsOverview from '../solutions/SolutionsOverview';

type SectionMap = {
  [key: string]: string;
};

interface SolutionSections {
  overview: SectionMap;
  automation: SectionMap;
  expert: SectionMap;
  fixdb: SectionMap;
  onboarding: SectionMap;
  outage: SectionMap;
  project: SectionMap;
  rpa: SectionMap;
  workflow: SectionMap;
};

// Map solution IDs to their section IDs
const SECTION_IDS: SolutionSections = {
  overview: {
    'Overview': '0.0'
  },
  automation: {
    'Overview': '1.0',
    'Business Case': '1.1',
    'Feedback': '1.2'
  },
  expert: {
    'Overview': '2.0',
    'Prototype': '2.1',
    'Feedback': '2.2'
  },
  fixdb: {
    'Overview': '3.0',
    'Prototype': '3.1',
    'Feedback': '3.2'
  },
  onboarding: {
    'Overview': '4.0',
    'Mockup': '4.1',
    'Feedback': '4.2'
  },
  outage: {
    'Overview': '5.0',
    'Mockup': '5.1',
    'Feedback': '5.2'
  },
  project: {
    'Overview': '6.0',
    'Prototype': '6.1',
    'Feedback': '6.2'
  },
  rpa: {
    'Overview': '7.0',
    'Video': '7.1',
    'Feedback': '7.2'
  },
  workflow: {
    'Overview': '8.0',
    'Prototype': '8.1',
    'Feedback': '8.2'
  }
} as const;

const Solutions: React.FC = () => {
  const { userId } = useUser();
  const [activeSolution, setActiveSolution] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState('Overview');
  const [visitedSections, setVisitedSections] = useState<{ [key: string]: Set<string> }>({
    overview: new Set(['Overview'])
  });
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const solutions = [
    { 
      id: 'automation', 
      name: 'Automation-As-A-Service', 
      component: AutomationService,
      sections: ['Overview', 'Business Case', 'Feedback']
    },
    { 
      id: 'expert', 
      name: 'Expert Path', 
      component: ExpertPath,
      sections: ['Overview', 'Prototype', 'Feedback']
    },
    { 
      id: 'fixdb', 
      name: 'Master Fix Database', 
      component: MasterFixDatabase,
      sections: ['Overview', 'Prototype', 'Feedback']
    },
    { 
      id: 'onboarding', 
      name: 'Onboarding Navigator', 
      component: OnboardingNavigator,
      sections: ['Overview', 'Mockup', 'Feedback']
    },
    { 
      id: 'outage', 
      name: 'Outage Hub', 
      component: OutageHub,
      sections: ['Overview', 'Mockup', 'Feedback']
    },
    { 
      id: 'project', 
      name: 'Project Path', 
      component: ProjectPath,
      sections: ['Overview', 'Prototype', 'Feedback']
    },
    { 
      id: 'rpa', 
      name: 'Robotic Process Automation', 
      component: RoboticProcessAutomation,
      sections: ['Overview', 'Video', 'Feedback']
    },
    { 
      id: 'workflow', 
      name: 'Workflow Orchestrator', 
      component: WorkflowOrchestrator,
      sections: ['Overview', 'Prototype', 'Feedback']
    }
  ] as const;

  // Load stored progress and checks first
  useEffect(() => {
    const loadProgressAndChecks = async () => {
      if (userId) {
        setIsLoading(true);
        const data = await getProgress(userId);
        setProgress(data.solutions);

        // Convert stored checks string to Set
        if (data.solutionsChecks) {
          const checksArray = data.solutionsChecks.split(',');
          const newVisitedSections: { [key: string]: Set<string> } = {};

          checksArray.forEach(id => {
            Object.entries(SECTION_IDS).forEach(([solutionId, sections]) => {
              Object.entries(sections).forEach(([sectionName, sectionId]) => {
                if (sectionId === id) {
                  if (!newVisitedSections[solutionId]) {
                    newVisitedSections[solutionId] = new Set();
                  }
                  newVisitedSections[solutionId].add(sectionName);
                }
              });
            });
          });

          // Ensure Overview is always marked as visited
          if (!newVisitedSections['overview']) {
            newVisitedSections['overview'] = new Set(['Overview']);
          } else {
            newVisitedSections['overview'].add('Overview');
          }

          setVisitedSections(newVisitedSections);
        }

        setIsLoading(false);
      }
    };
    loadProgressAndChecks();
  }, [userId]);

  const calculateProgress = () => {
    const totalSections = solutions.reduce((total, solution) => total + solution.sections.length, 0);
    // Add 1 to total sections to account for the overview page
    const adjustedTotal = totalSections + 1;
    
    let visitedCount = Object.values(visitedSections).reduce(
      (total, sections) => total + sections.size, 0
    ); 
    
    // Ensure we don't exceed 100%
    visitedCount = Math.min(visitedCount, adjustedTotal);
    
    return Math.min(Math.round((visitedCount / adjustedTotal) * 100), 100);
  };

  // Update progress and checks when sections are visited
  useEffect(() => {
    const updateProgressAndChecks = async () => {
      if (userId && !isLoading) {
        const calculatedProgress = calculateProgress();
        if (calculatedProgress > progress) {
          await updateProgress(userId, 'solutions', calculatedProgress);
          setProgress(calculatedProgress);
        }

        // Convert visited sections to section IDs
        const checksArray: string[] = [];
        Object.entries(visitedSections).forEach(([solutionId, sections]) => {
          const solutionSections = SECTION_IDS[solutionId as keyof SolutionSections];
          if (solutionSections) {
            sections.forEach(section => {
              const sectionId = solutionSections[section];
              if (sectionId) {
                checksArray.push(sectionId);
              }
            });
          }
        });

        if (checksArray.length > 0) {
          await updateChecks(userId, 'solutions', checksArray.join(','));
        }
      }
    };
    updateProgressAndChecks();
  }, [visitedSections, userId, progress, isLoading]);

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
    if (activeSolution) {
      setVisitedSections(prev => {
        const newSections = new Set(prev[activeSolution] || []);
        newSections.add(section);
        return { ...prev, [activeSolution]: newSections };
      });
    }
  };

  const handleSolutionClick = (solutionId: string) => {
    setActiveSolution(solutionId);
    setActiveSection('Overview');
    setVisitedSections(prev => {
      const newSections = new Set(prev[solutionId] || []);
      newSections.add('Overview');
      return { ...prev, [solutionId]: newSections };
    });
  };

  const isSectionVisited = (solutionId: string, section: string) => {
    // Special handling for automation service
    if (solutionId === 'automation' && section === 'Overview') {
      return visitedSections['overview']?.has('Overview') || false;
    }

    return visitedSections[solutionId]?.has(section) || false;
  };

  const isSolutionFullyVisited = (solutionId: string) => {
    const solution = solutions.find(s => s.id === solutionId);
    const visited = visitedSections[solutionId];
    
    // Special handling for automation service
    if (solutionId === 'automation') {
      return visited && solution?.sections.every(section => {
        if (section === 'Overview') {
          return visitedSections['overview']?.has('Overview');
        }
        return visited.has(section);
      });
    }
    
    return visited && solution?.sections.every(section => {
      return visited.has(section);
    });
  };

  return (
    <div className="flex">
      <div className="w-1/4 bg-surface-50 p-4">
        <h2 className="text-xl font-bold mb-4">Our Solutions</h2>
        <ul>
          <li className="mb-2">
            <button
              onClick={() => {
                setActiveSolution(null);
                setVisitedSections(prev => ({
                  ...prev,
                  overview: new Set(['Overview'])
                }));
              }}
              className={`w-full text-left p-2 rounded flex items-center justify-between ${
                !activeSolution ? 'bg-primary-300 text-white' : 'hover:bg-surface-100'
              }`}
            >
              <span>Overview</span>
              {visitedSections['overview']?.has('Overview') ? (
                <CheckCircle className="text-success-300" size={20} />
              ) : (
                <Circle className="text-warning-300" size={20} />
              )}
            </button>
          </li>
          {solutions.map((solution) => (
            <li key={solution.id} className="mb-2">
              <button
                onClick={() => handleSolutionClick(solution.id)}
                className={`w-full text-left p-2 rounded flex items-center justify-between ${
                  activeSolution === solution.id ? 'bg-primary-300 text-white' : 'hover:bg-surface-100'
                }`}
              >
                <span>{solution.name}</span>
                {isSolutionFullyVisited(solution.id) ? (
                  <CheckCircle className="text-success-300" size={20} />
                ) : (
                  <Circle className="text-warning-300" size={20} />
                )}
              </button>
              {activeSolution === solution.id && (
                <div className="ml-4 mt-2">
                  {solution.sections.map((section) => (
                    <button
                      key={section}
                      onClick={() => handleSectionClick(section)}
                      className={`w-full text-left p-2 rounded flex items-center justify-between ${
                        activeSection === section ? 'bg-blue-100' : 'hover:bg-gray-100'
                      }`}
                    >
                      <span>{section}</span>
                      {isSectionVisited(solution.id, section) ? (
                        <CheckCircle className="text-success-300" size={16} />
                      ) : (
                        <Circle className="text-warning-300" size={16} />
                      )}
                    </button>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="mt-4 p-4 bg-white rounded-lg shadow">
          <p className="text-sm font-semibold">Progress: {progress}%</p>
          <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
            <div
              className="bg-primary-300 h-2 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>

      <div className="w-3/4 p-8">
        {activeSolution ? (
          solutions.map((solution) => (
            solution.id === activeSolution && (
              <solution.component 
                key={solution.id}
                activeSection={activeSection}
                name={solution.name}
              />
            )
          ))
        ) : (
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">FixFinder Solutions</h1>
            <p className="text-xl">Select a solution from the menu to view its details.</p>
            <SolutionsOverview onSolutionClick={handleSolutionClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Solutions;