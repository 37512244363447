import React, { useState, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext';
import { getProgress, updateProgress, updateChecks } from '../../services/nocodbService';
import EnhancedFigmaViewer from '../EnhancedFigmaViewer';
import { ArrowRight, CheckCircle, Circle } from 'lucide-react';
import DesktopFeedbackForm from '../DesktopFeedbackForm';

// Section IDs for checkmark tracking
const SECTION_IDS = {
  OVERVIEW: '1.0',
  REDESIGN: '2.0',
  REDESIGN_HOME: '2.1',
  REDESIGN_KNOWLEDGE: '2.2',
  REDESIGN_TROUBLESHOOT: '2.3',
  REDESIGN_LIBRARY: '2.4',
  REDESIGN_TICKETS: '2.5',
  REDESIGN_STATUS: '2.6',
  REDESIGN_SEARCH: '2.7',
  VALIDATION: '3.0',
  LABS: '4.0',
  LABS_BOT: '4.1',
  LABS_MIGRATION: '4.2',
  FEEDBACK: '5.0'
};

const Desktop: React.FC = () => {
  const { userId } = useUser();
  const [activeView, setActiveView] = useState('home');
  const [activeSection, setActiveSection] = useState('Overview');
  const [visitedSections, setVisitedSections] = useState<Set<string>>(new Set(['Overview']));
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const views = [
    { id: 'home', label: 'Home', fileLabel: 'desktop_home', description: 'The Home view provides a central hub for users to access key features and information at a glance.' },
    { id: 'knowledge', label: 'Knowledge', fileLabel: 'desktop_knowledge', description: 'The Knowledge section offers a comprehensive database of articles and resources for self-help and learning.' },
    { id: 'troubleshoot', label: 'Troubleshoot', fileLabel: 'desktop_troubleshoot', description: 'The Troubleshoot area guides users through step-by-step processes to resolve common issues quickly.' },
    { id: 'library', label: 'Library', fileLabel: 'desktop_library', description: 'The Library houses a collection of documents, manuals, and other reference materials for easy access.' },
    { id: 'tickets', label: 'Tickets', fileLabel: 'desktop_tickets', description: 'The Tickets section allows users to view, create, and manage support tickets efficiently.' },
    { id: 'system_status', label: 'System Status', fileLabel: 'system_status', description: 'The System Status page provides real-time updates on the health and performance of various system components.' },
    { id: 'search', label: 'Search', fileLabel: 'desktop_search', description: 'The Search functionality allows users to quickly find specific information across all sections of the application.' },
  ];

  const sections = [
    { id: 'overview', label: 'Overview' },
    { id: 'redesign', label: 'Redesign' },
    { id: 'validation', label: 'Validation' },
    { id: 'labs', label: 'Labs' },
    { id: 'feedback', label: 'Feedback' }
  ];

  const labsViews = [
    { id: 'bot', label: 'Bring Your Own Bot', fileLabel: 'desktop_bot', description: 'Custom bot integration and management capabilities.' },
    { id: 'migration', label: 'Migration Mode', fileLabel: 'desktop_migration', description: 'Advanced tooling to assist users with technology migrations.' }
  ];


  // Load stored progress and checks first
  useEffect(() => {
    const loadProgressAndChecks = async () => {
      if (userId) {
        setIsLoading(true);
        const data = await getProgress(userId);
        setProgress(data.desktop);
        
        // Convert stored checks string to Set
        if (data.desktopChecks) {
          const checksArray = data.desktopChecks.split(',');
          setVisitedSections(new Set(checksArray.map(id => {
            // Convert section IDs back to section names
            switch (id) {
              case SECTION_IDS.OVERVIEW: return 'Overview';
              case SECTION_IDS.REDESIGN: return 'Redesign';
              case SECTION_IDS.REDESIGN_HOME: return 'redesign-home';
              case SECTION_IDS.REDESIGN_KNOWLEDGE: return 'redesign-knowledge';
              case SECTION_IDS.REDESIGN_TROUBLESHOOT: return 'redesign-troubleshoot';
              case SECTION_IDS.REDESIGN_LIBRARY: return 'redesign-library';
              case SECTION_IDS.REDESIGN_TICKETS: return 'redesign-tickets';
              case SECTION_IDS.REDESIGN_STATUS: return 'redesign-system_status';
              case SECTION_IDS.REDESIGN_SEARCH: return 'redesign-search';
              case SECTION_IDS.VALIDATION: return 'Validation';
              case SECTION_IDS.FEEDBACK: return 'Feedback';
              case SECTION_IDS.LABS: return 'Labs';
              case SECTION_IDS.LABS_BOT: return 'labs-bot';
              case SECTION_IDS.LABS_MIGRATION: return 'labs-migration';
              default: return '';
            }
          }).filter(Boolean)));
        }
        
        setIsLoading(false);
      }
    };
    loadProgressAndChecks();
  }, [userId]);

  const calculateProgress = () => {
    const totalSections = sections.length + views.length + labsViews.length;
    const visitedCount = visitedSections.size;
    return Math.round((visitedCount / totalSections) * 100);
  };

  // Update progress and checks when sections are visited
  useEffect(() => {
    const updateProgressAndChecks = async () => {
      if (userId && !isLoading) {
        const calculatedProgress = calculateProgress();
        if (calculatedProgress > progress) {
          await updateProgress(userId, 'desktop', calculatedProgress);
          setProgress(calculatedProgress);
        }

        // Convert visited sections to section IDs
        const checksArray = Array.from(visitedSections).map(section => {
          switch (section) {
            case 'Overview': return SECTION_IDS.OVERVIEW;
            case 'Redesign': return SECTION_IDS.REDESIGN;
            case 'redesign-home': return SECTION_IDS.REDESIGN_HOME;
            case 'redesign-knowledge': return SECTION_IDS.REDESIGN_KNOWLEDGE;
            case 'redesign-troubleshoot': return SECTION_IDS.REDESIGN_TROUBLESHOOT;
            case 'redesign-library': return SECTION_IDS.REDESIGN_LIBRARY;
            case 'redesign-tickets': return SECTION_IDS.REDESIGN_TICKETS;
            case 'redesign-system_status': return SECTION_IDS.REDESIGN_STATUS;
            case 'redesign-search': return SECTION_IDS.REDESIGN_SEARCH;
            case 'Validation': return SECTION_IDS.VALIDATION;
            case 'Labs': return SECTION_IDS.LABS;
            case 'labs-bot': return SECTION_IDS.LABS_BOT;
            case 'labs-migration': return SECTION_IDS.LABS_MIGRATION;
            case 'Feedback': return SECTION_IDS.FEEDBACK;
            default: return '';
          }
        }).filter(Boolean);

        await updateChecks(userId, 'desktop', checksArray.join(','));
      }
    };
    updateProgressAndChecks();
  }, [visitedSections, userId, progress, isLoading]);

  const handleSectionClick = (section: string) => {
    setActiveSection(section);
    setVisitedSections(prev => new Set([...prev, section]));
    if (section === 'Redesign') {
      setVisitedSections(prev => new Set([...prev, 'redesign-home']));
    }
    if (section === 'Labs') {
      setVisitedSections(prev => new Set([...prev, 'labs-bot']));
    }
  };

  const getNextViewsToPreload = (currentView: string): string[] => {
    const currentIndex = views.findIndex(view => view.id === currentView);
    return views
      .slice(currentIndex + 1, currentIndex + 4)
      .map(view => view.fileLabel);
  };

  const handleViewClick = (viewId: string) => {
    setActiveView(viewId);
    setVisitedSections(prev => new Set([...prev, `redesign-${viewId}`]));
  };

  const handleLabsViewClick = (viewId: string) => {
    setActiveView(viewId);
    setVisitedSections(prev => new Set([...prev, `labs-${viewId}`]));
  };

  const isSectionVisited = (section: string) => {
    // Special handling for Labs section
    if (section === 'Labs') {
      return labsViews.every(view => visitedSections.has(`labs-${view.id}`));
    }
    // Special handling for Redesign section
    if (section === 'Redesign') {
      return views.every(view => visitedSections.has(`redesign-${view.id}`));
    }
    return visitedSections.has(section);
  };

  const activeViewData = views.find(v => v.id === activeView) || views[0];

  return (
    <div className="flex">
      <div className="w-1/4 bg-surface-50 p-4">
        <h2 className="text-xl font-bold mb-4">Desktop Application</h2>
        <ul>
          {sections.map((section) => (
            <li key={section.id} className="mb-2">
              <button
                onClick={() => handleSectionClick(section.label)}
                className={`w-full text-left p-2 rounded flex items-center justify-between ${
                  activeSection === section.label 
                    ? 'bg-primary-300 text-white' 
                    : 'hover:bg-surface-100'
                }`}
              >
                <span>{section.label}</span>
                {isSectionVisited(section.label) ? (
                  <CheckCircle className="text-success-300" size={16} />
                ) : (
                  <Circle className="text-warning-300" size={16} />
                )}
              </button>
              {section.label === 'Redesign' && activeSection === 'Redesign' && (
                <div className="ml-4 mt-2 space-y-1">
                  {views.map((view) => (
                    <button
                      key={view.id}
                      onClick={() => handleViewClick(view.id)}
                      className={`w-full text-left p-2 rounded flex items-center justify-between text-sm ${
                        activeView === view.id ? 'bg-blue-100' : 'hover:bg-gray-100'
                      }`}
                    >
                      <span>{view.label}</span>
                      {isSectionVisited(`redesign-${view.id}`) ? (
                        <CheckCircle className="text-success-300" size={14} />
                      ) : (
                        <Circle className="text-warning-300" size={14} />
                      )}
                    </button>
                  ))}
                </div>
              )}
              {section.label === 'Labs' && activeSection === 'Labs' && (
                <div className="ml-4 mt-2 space-y-1">
                  {labsViews.map((view) => (
                    <button
                      key={view.id}
                      onClick={() => handleLabsViewClick(view.id)}
                      className={`w-full text-left p-2 rounded flex items-center justify-between text-sm ${
                        activeView === view.id ? 'bg-blue-100' : 'hover:bg-gray-100'
                      }`}
                    >
                      <span>{view.label}</span>
                      {isSectionVisited(`labs-${view.id}`) ? (
                        <CheckCircle className="text-success-300" size={14} />
                      ) : (
                        <Circle className="text-warning-300" size={14} />
                      )}
                    </button>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>

        <div className="mt-4 p-4 bg-white rounded-lg shadow">
          <p className="text-sm font-semibold">Progress: {progress}%</p>
          <div className="w-full bg-gray-200 rounded-full h-2 mt-2">
            <div
              className="bg-primary-300 h-2 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>

      <div className="w-3/4 p-8">
        {activeSection === 'Overview' ? (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Desktop Application Overview</h2>
            
            <div className="w-full max-w-2xl mx-auto mb-6">
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} className="rounded-lg overflow-hidden">
                <iframe
                  src="https://player.vimeo.com/video/1037133777?badge=0&autopause=0&player_id=0&app_id=58479"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Desktop Application"
                />
              </div>
              <script async src="https://player.vimeo.com/api/player.js" />
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Overview</h3>
              <p className="mb-4">
                The FixFinder Desktop Application is a comprehensive solution designed to streamline IT support and knowledge management. 
                It provides a unified interface for accessing support resources, troubleshooting guides, and real-time system information.
              </p>
              <p className="mb-4">
                Built with user experience in mind, the application offers intuitive navigation and powerful search capabilities to help users 
                quickly find the information they need.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">Key Features</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                  Unified knowledge base access
                </li>
                <li className="flex items-center">
                  <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                  Integrated troubleshooting tools
                </li>
                <li className="flex items-center">
                  <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                  Real-time system monitoring
                </li>
                <li className="flex items-center">
                  <ArrowRight className="w-5 h-5 text-blue-500 mr-2" />
                  Smart search capabilities
                </li>
              </ul>
            </div>
          </div>
        ) : activeSection === 'Redesign' ? (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Desktop Application Redesign</h2>
            
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <h3 className="text-xl font-semibold mb-2">{activeViewData.label}</h3>
              <p className="text-gray-700">{activeViewData.description}</p>
            </div>

            <div className="w-full max-w-3xl">
              <EnhancedFigmaViewer 
                fileLabel={activeViewData.fileLabel}
                preloadLabels={getNextViewsToPreload(activeView)}
              />
            </div>
          </div>
        ) : activeSection === 'Validation' ? (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Desktop Application Validation</h2>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="aspect-[8.5/11] w-full">
                <iframe
                  src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6719bf8799a5d62431b2fa95_FixFinder_and_Datos_Insights.pdf"
                  className="w-full h-full border-0 rounded-lg"
                  title="FixFinder and Datos Insights"
                />
              </div>
              
              <div className="mt-4 text-center">
                <a
                  href="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6719bf8799a5d62431b2fa95_FixFinder_and_Datos_Insights.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-800"
                >
                  <ArrowRight className="w-4 h-4 mr-2" />
                  Open PDF in new tab
                </a>
              </div>
            </div>
          </div>
        ) : activeSection === 'Feedback' ? (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Share Your Thoughts</h2>
            <DesktopFeedbackForm />
          </div>
        ) : activeSection === 'Labs' ? (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold mb-6 text-center">Labs Features</h2>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold mb-4">{
                labsViews.find(v => v.id === activeView)?.label || 'Select a Labs feature'
              }</h3>
              {activeView && (
                <EnhancedFigmaViewer 
                  fileLabel={labsViews.find(v => v.id === activeView)?.fileLabel || ''}
                  preloadLabels={[]}
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Desktop;
