import React, { useState } from 'react';
import { ThumbsUp, ThumbsDown, Thermometer } from 'lucide-react';
import { useFeedbackSubmission } from '../hooks/useFeedbackSubmission';
import type { FeedbackFormState } from '../types/feedback';

interface FeedbackFormProps {
  solutionName: string;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ solutionName }) => {
  const [formState, setFormState] = useState<FeedbackFormState>({
    rating: 5,
    Applications: 0,
    Knowledge: 0,
    Automation: 0,
    Status_Page: 0,
    Migrations: 0,
    Ticketing: 0,
    Bots: 0,
    addressesChallenges: null,
    challengeDetails: '',
    challengesImportance: '',
    paymentConsideration: null,
    competitorDetails: '',
    additionalFeedback: '',
    bookFollowUp: null
  });

  const { isSubmitting, error, submitted, handleSubmit } = useFeedbackSubmission({
    solutionName
  });

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmit(formState);
  };

  const BubbleButton = ({ value, onClick, selected }: { value: boolean; onClick: () => void; selected: boolean }) => (
    <button
      type="button"
      onClick={onClick}
      className={`w-8 h-8 rounded-full flex items-center justify-center focus:outline-none transition-colors ${
        selected ? 'bg-primary-300 text-white' : 'bg-surface-50 text-surface-200 hover:bg-surface-100'
      }`}
    >
      {value ? <ThumbsUp size={16} /> : <ThumbsDown size={16} />}
    </button>
  );

  if (submitted) {
    return (
      <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
        <p className="font-bold">Thank you for your feedback!</p>
        <p>Your input helps us improve {solutionName}.</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">
      <h3 className="text-2xl font-semibold mb-6 text-center">Share Your Thoughts on {solutionName}</h3>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      <form onSubmit={handleFormSubmit} className="space-y-6">
        <div className="mb-6">
          <label className="block mb-2 font-medium">1. How would you rate this solution?</label>
          <div className="flex items-center space-x-4">
            <Thermometer className="text-blue-500" size={24} />
            <input
              type="range"
              min="1"
              max="10"
              value={formState.rating}
              onChange={(e) => setFormState(prev => ({ ...prev, rating: Number(e.target.value) }))}
              className="w-full h-2 bg-surface-50 rounded-lg appearance-none cursor-pointer"
            />
            <span className="font-bold text-lg w-8 text-center">{formState.rating}</span>
            <Thermometer className="text-red-500" size={24} />
          </div>
        </div>

        <div className="mb-6">
          <label className="block mb-2 font-medium">2. Does it address any current challenges you face?</label>
          <div className="flex space-x-4">
            <BubbleButton 
              value={true} 
              onClick={() => setFormState(prev => ({ ...prev, addressesChallenges: true }))} 
              selected={formState.addressesChallenges === true} 
            />
            <BubbleButton 
              value={false} 
              onClick={() => setFormState(prev => ({ ...prev, addressesChallenges: false }))} 
              selected={formState.addressesChallenges === false} 
            />
          </div>
          {formState.addressesChallenges === true && (
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium">What challenges does it address?</label>
              <textarea
                value={formState.challengeDetails}
                onChange={(e) => setFormState(prev => ({ ...prev, challengeDetails: e.target.value }))}
                className="w-full p-2 border rounded bg-surface-50 text-surface-200"
                rows={3}
              />
            </div>
          )}
        </div>

        <div className="mb-6">
          <label className="block mb-2 font-medium">3. How would you describe the importance of these challenges?</label>
          <select
            value={formState.challengesImportance}
            onChange={(e) => setFormState(prev => ({ ...prev, challengesImportance: e.target.value }))}
            className="w-full p-2 border rounded bg-surface-50 text-surface-200"
          >
            <option value="">Select an option</option>
            <option value="not_urgent">Not Urgent</option>
            <option value="researching">Researching</option>
            <option value="planning">Planning</option>
            <option value="budgeting">Budgeting</option>
            <option value="seeking_solutions">Seeking Solutions</option>
          </select>
        </div>

        <div className="mb-6">
          <label className="block mb-2 font-medium">4. Would you consider paying for this solution if available?</label>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => setFormState(prev => ({ ...prev, paymentConsideration: true }))}
              className={`px-4 py-2 rounded-full focus:outline-none transition-colors ${
                formState.paymentConsideration === true
                  ? 'bg-primary-300 text-white'
                  : 'bg-surface-50 text-surface-200 hover:bg-surface-100'
              }`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setFormState(prev => ({ ...prev, paymentConsideration: false }))}
              className={`px-4 py-2 rounded-full focus:outline-none transition-colors ${
                formState.paymentConsideration === false
                  ? 'bg-primary-300 text-white'
                  : 'bg-surface-50 text-surface-200 hover:bg-surface-100'
              }`}
            >
              No
            </button>
          </div>
          {formState.paymentConsideration === true && (
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium">Which vendors with similar solutions are you considering?</label>
              <textarea
                value={formState.competitorDetails}
                onChange={(e) => setFormState(prev => ({ ...prev, competitorDetails: e.target.value }))}
                className="w-full p-2 border rounded bg-surface-50 text-surface-200"
                rows={3}
                placeholder="Please list the vendors you're considering..."
              />
            </div>
          )}
        </div>

        <div className="mb-6">
          <label className="block mb-2 font-medium">5. Would you like to book a follow-up call to discuss this solution?</label>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => setFormState(prev => ({ ...prev, bookFollowUp: true }))}
              className={`px-4 py-2 rounded-full focus:outline-none transition-colors ${
                formState.bookFollowUp === true
                  ? 'bg-primary-300 text-white'
                  : 'bg-surface-50 text-surface-200 hover:bg-surface-100'
              }`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setFormState(prev => ({ ...prev, bookFollowUp: false }))}
              className={`px-4 py-2 rounded-full focus:outline-none transition-colors ${
                formState.bookFollowUp === false
                  ? 'bg-primary-300 text-white'
                  : 'bg-surface-50 text-surface-200 hover:bg-surface-100'
              }`}
            >
              No
            </button>
          </div>
        </div>

        {formState.bookFollowUp === true && (
          <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
            <p>Great! Please book a time that works for you on <a href="https://calendly.com/fixfinder" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Calendly</a>, you can also hit 'Submit Feedback' now and we'll reach out to you later as a follow-up.</p>
          </div>
        )}

        {formState.bookFollowUp === false && (
          <div className="mt-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
            <p>That's okay. Feel free to reach out to us anytime at <a href="mailto:research@fixfinder.com" className="text-blue-500 hover:underline">research@fixfinder.com</a></p>
          </div>
        )}

        <div className="mb-6">
          <label className="block mb-2 font-medium">6. Additional thoughts or suggestions:</label>
          <textarea
            value={formState.additionalFeedback}
            onChange={(e) => setFormState(prev => ({ ...prev, additionalFeedback: e.target.value }))}
            className="w-full p-2 border rounded bg-surface-50 text-surface-200"
            rows={5}
          ></textarea>
        </div>

        <button 
          type="submit" 
          disabled={isSubmitting}
          className={`w-full bg-primary-300 text-white px-4 py-2 rounded hover:bg-primary-400 transition-colors ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;