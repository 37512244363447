import { Project, Solution, QueuedIntegration } from './types';

export const desktopSolutions: Project[] = [
  { 
    name: 'Ticketing', 
    status: 'awaiting-feedback', 
    description: 'Advanced ticketing system integration and management.' 
  },
  { 
    name: 'Automation', 
    status: 'awaiting-feedback', 
    description: 'Automated workflows and task management.' 
  },
  { 
    name: 'Applications', 
    status: 'awaiting-feedback', 
    description: 'Integrated application management and deployment.' 
  },
  { 
    name: 'Status Page', 
    status: 'awaiting-feedback', 
    description: 'Real-time system status monitoring and reporting.' 
  },
  { 
    name: 'Knowledge Bases', 
    status: 'awaiting-feedback', 
    description: 'Centralized knowledge management and documentation.' 
  },
  { 
    name: 'Troubleshooting', 
    status: 'awaiting-feedback', 
    description: 'Advanced diagnostic and problem-solving tools.' 
  },
  { 
    name: 'Onboarding', 
    status: 'awaiting-feedback', 
    description: 'Streamlined user onboarding and training processes.' 
  },
  { 
    name: 'Bring Your Own Bot', 
    status: 'awaiting-feedback', 
    description: 'Custom bot integration and management capabilities.' 
  },
  {
    name: 'Migration Mode',
    status: 'awaiting-feedback',
    description: 'Advanced tooling to assist users with technology migrations.'
  }
];

export const cloudSolutions: Solution[] = [
  { 
    name: 'Automation-as-a-Service', 
    status: 'awaiting-feedback',
    description: 'Enterprise automation solutions for modern businesses'
  },
  { 
    name: 'Expert Path', 
    status: 'awaiting-feedback',
    description: 'Advanced troubleshooting assistant for IT professionals'
  },
  { 
    name: 'Master Fix Database', 
    status: 'awaiting-feedback',
    description: 'The Actual Intelligence Engine behind FixFinder'
  },
  { 
    name: 'Onboarding Navigator', 
    status: 'awaiting-feedback',
    description: 'Revolutionizing employee onboarding through intelligent automation'
  },
  { 
    name: 'Outage Hub', 
    status: 'awaiting-feedback',
    description: 'Streamline outage management and incident response'
  },
  { 
    name: 'Project Path', 
    status: 'awaiting-feedback',
    description: 'Expert guidance for complex IT migrations'
  },
  { 
    name: 'Robotic Process Automation', 
    status: 'awaiting-feedback',
    description: 'Automate repetitive tasks and streamline operations'
  },
  { 
    name: 'Workflow Orchestrator', 
    status: 'awaiting-feedback',
    description: 'Intelligent workflow management and automation'
  }
];

export const inDevelopment: Project[] = [
  { 
    name: 'Autotask Ticketing', 
    status: 'completed', 
    description: 'Full integration with Autotask ticketing system.' 
  },
  { 
    name: 'ConnectWise', 
    status: 'in-progress', 
    description: 'Ongoing integration with ConnectWise platform.' 
  },
  { 
    name: 'NinjaRMM', 
    status: 'in-progress', 
    description: 'Integration with NinjaRMM for remote monitoring and management.' 
  },
  { 
    name: 'Datto RMM', 
    status: 'beta', 
    description: 'Beta testing integration with Datto RMM solution.' 
  },
  { 
    name: 'Scribe', 
    status: 'completed', 
    description: 'Integration with Scribe documentation platform.' 
  }
];

export const queuedIntegrations: QueuedIntegration[] = [
  { name: '4me Documentation', votes: 0 },
  { name: 'Atera Documentation', votes: 0 },
  { name: 'ConnectWise Manage', votes: 0 },
  { name: 'ConnectWise Automate', votes: 0 },
  { name: 'Freshdesk Documentation', votes: 0 },
  { name: 'HaloPSA/ITSM Documentation', votes: 0 },
  { name: 'Kaseya BMS Documentation', votes: 0 },
  { name: 'osTicket Documentation', votes: 0 },
  { name: 'Repairshopr / Syncro Documentation', votes: 0 },
  { name: 'ServiceNow Documentation', votes: 0 },
  { name: 'SuperOps Documentation', votes: 0 },
  { name: 'Topdesk Documentation', votes: 0 },
  { name: 'Zendesk Documentation', votes: 0 }
];