import React, { useState } from 'react';
import { 
  Zap, 
  AlertTriangle,
  Target, 
  HelpCircle, 
  Database, 
  GraduationCap, 
  Bot, 
  GitBranch,
  ArrowLeft
} from 'lucide-react';
import { Link } from 'react-router-dom';

interface SolutionsOverviewProps {
  onSolutionClick: (solutionId: string) => void;
}

const solutions = [
  {
    name: 'Automation Services',
    description: 'Streamline operations with intelligent automation',
    icon: Zap,
    color: 'bg-blue-500',
    link: '#automation'
  },
  {
    name: 'Outage Hub',
    description: 'Streamline outage management and incident response',
    icon: AlertTriangle,
    color: 'bg-indigo-500',
    link: '#outage'
  },
  {
    name: 'Project Execution',
    description: 'Expert guidance for complex migrations',
    icon: Target,
    color: 'bg-purple-500',
    link: '#project'
  },
  {
    name: 'Troubleshooting',
    description: 'AI-powered problem resolution',
    icon: HelpCircle,
    color: 'bg-pink-500',
    link: '#expert'
  },
  {
    name: 'Agnostic Knowledge',
    description: 'Vendor-neutral knowledge management',
    icon: Database,
    color: 'bg-red-500',
    link: '#fixdb'
  },
  {
    name: 'Onboarding/Training',
    description: 'Streamlined employee integration',
    icon: GraduationCap,
    color: 'bg-orange-500',
    link: '#onboarding'
  },
  {
    name: 'Robotic Automation',
    description: 'Intelligent process automation',
    icon: Bot,
    color: 'bg-yellow-500',
    link: '#rpa'
  },
  {
    name: 'Workflow Automation',
    description: 'Smart workflow orchestration',
    icon: GitBranch,
    color: 'bg-green-500',
    link: '#workflow'
  }
];

const SolutionsOverview: React.FC<SolutionsOverviewProps> = ({ onSolutionClick }) => {
  const [flippedCard, setFlippedCard] = useState<number | null>(null);

  const handleSolutionClick = (solutionId: string) => {
    onSolutionClick(solutionId);
  };

  return (
    <div className="relative max-w-4xl mx-auto mt-12">
      {/* Overview Button */}
      <button
        onClick={() => setFlippedCard(null)}
        className="absolute top-0 left-0 flex items-center text-blue-600 hover:text-blue-800 transition-colors mb-4"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Overview
      </button>

      {/* Solutions Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {solutions.map((solution, index) => (
          <div
            key={solution.name}
            className="relative h-32 perspective-1000"
            onClick={() => setFlippedCard(flippedCard === index ? null : index)}
          >
            <div className={`absolute w-full h-full transition-transform duration-500 transform-style-preserve-3d cursor-pointer ${
              flippedCard === index ? 'rotate-y-180' : ''
            }`}>
              {/* Front of card */}
              <div className={`absolute w-full h-full ${solution.color} text-white p-4 rounded-lg shadow-lg backface-hidden`}>
                <div className="flex items-start space-x-4">
                  <solution.icon className="w-8 h-8 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-1">{solution.name}</h3>
                    <p className="text-sm opacity-90">{solution.description}</p>
                  </div>
                </div>
              </div>
              {/* Back of card */}
              <div className={`absolute w-full h-full bg-white p-4 rounded-lg shadow-lg backface-hidden rotate-y-180`}>
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <h3 className="font-semibold text-lg mb-1 text-gray-800">{solution.name}</h3>
                    <p className="text-sm text-gray-600">{solution.description}</p>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      const solutionId = solution.link.replace('#', '');
                      handleSolutionClick(solutionId);
                    }}
                    className="mt-2 inline-flex items-center justify-center px-3 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolutionsOverview;