import React, { useState } from 'react'
import { useAuth } from '@frontegg/react'
import { Thermometer } from 'lucide-react'
import { submitFeedback } from '../../services/nocodbService'

const Feedback: React.FC = () => {
  const { user } = useAuth()
  const [overallRating, setOverallRating] = useState(5)
  const [otherIdeas, setOtherIdeas] = useState('')
  const [lookingAtVendors, setLookingAtVendors] = useState<boolean | null>(null)
  const [vendorsList, setVendorsList] = useState('')
  const [bookFollowUp, setBookFollowUp] = useState<boolean | null>(null)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    
    if (!user?.id) {
      setError('User ID not found. Please ensure you are logged in.');
      return;
    }

    setIsSubmitting(true);
    try {
      console.log('Submitting feedback with data:', {
        userId: user.id,
        rating: overallRating,
        ideas: otherIdeas,
        vendors: lookingAtVendors ? vendorsList : '',
        meeting: bookFollowUp ? 'Yes' : 'No'
      });

      await submitFeedback({
        userId: user.id,
        rating: overallRating,
        ideas: otherIdeas,
        vendors: lookingAtVendors ? vendorsList : '',
        meeting: bookFollowUp ? 'Yes' : 'No'
      });
      
      setSubmitted(true)
      setError(null)
    } catch (err) {
      setError('Failed to submit feedback. Please try again.');
      console.error('Error submitting feedback:', err);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (submitted) {
    return (
      <div className="max-w-2xl mx-auto p-6">
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
          <p className="font-bold">Thank you for your feedback!</p>
          <p>We appreciate your input and will use it to improve FixFinder.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold mb-4">General Feedback</h1>
        <img 
          src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/67469a2d750a85c0d2d17520_FeedbackMicrophone.png"
          alt="Feedback Microphone"
          className="mx-auto mb-8 max-w-full h-auto w-96 md:w-64"
        />
      </div>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            1. How would you rate the overall direction of the FixFinder platforms solutions?
          </label>
          <div className="flex items-center space-x-4">
            <Thermometer className="text-blue-500" size={24} />
            <input
              type="range"
              min="1"
              max="10"
              value={overallRating}
              onChange={(e) => setOverallRating(Number(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <span className="font-bold text-lg">{overallRating}</span>
            <Thermometer className="text-red-500" size={24} />
          </div>
          <div className="flex justify-between text-sm text-gray-600 mt-1">
            <span>Ice</span>
            <span>Hot</span>
          </div>
        </div>

        <div>
          <label htmlFor="otherIdeas" className="block text-sm font-medium text-gray-700 mb-2">
            2. Do you have any other ideas we didn't cover that would solve a problem you're having?
          </label>
          <textarea
            id="otherIdeas"
            value={otherIdeas}
            onChange={(e) => setOtherIdeas(e.target.value)}
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          ></textarea>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            3. Are you looking at any vendors who solve those problems?
          </label>
          <div className="space-x-4">
            <button
              type="button"
              onClick={() => setLookingAtVendors(true)}
              className={`px-4 py-2 rounded-full ${lookingAtVendors === true ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setLookingAtVendors(false)}
              className={`px-4 py-2 rounded-full ${lookingAtVendors === false ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              No
            </button>
          </div>
        </div>

        {lookingAtVendors && (
          <div>
            <label htmlFor="vendorsList" className="block text-sm font-medium text-gray-700 mb-2">
              Please list the vendors you're considering:
            </label>
            <textarea
              id="vendorsList"
              value={vendorsList}
              onChange={(e) => setVendorsList(e.target.value)}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Please list the vendors you're considering..."
            ></textarea>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            4. Would you like to book a follow-up call with us to discuss the Research Portal?
          </label>
          <div className="space-x-4">
            <button
              type="button"
              onClick={() => setBookFollowUp(true)}
              className={`px-4 py-2 rounded-full ${bookFollowUp === true ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setBookFollowUp(false)}
              className={`px-4 py-2 rounded-full ${bookFollowUp === false ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              No
            </button>
          </div>
        </div>

        {bookFollowUp === true && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
            <p>Great! Please book a time that works for you:</p>
            <a href="https://calendly.com/fixfinder" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              Schedule a call on Calendly
            </a>
          </div>
        )}

        {bookFollowUp === false && (
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
            <p>That's okay. Feel free to reach out to us anytime at <a href="mailto:research@fixfinder.com" className="text-blue-500 hover:underline">research@fixfinder.com</a></p>
          </div>
        )}

        <button
          type="submit"
          disabled={isSubmitting}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  )
}

export default Feedback