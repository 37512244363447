import React, { useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuth, ContextHolder } from '@frontegg/react'
import Sidebar from './components/Sidebar'
import Login from './components/Login'
import Welcome from './components/pages/Welcome'
import Reporting from './components/pages/Reporting'
import Desktop from './components/pages/Desktop'
import Solutions from './components/pages/Solutions'
import Integrations from './components/pages/Integrations'
import Portal from './components/pages/Portal'
import Roadmap from './components/pages/Roadmap'
import Feedback from './components/pages/Feedback'
import OAuthCallback from './components/OAuthCallback'
import { checkTenantAndUser } from './services/nocodbService'

const App: React.FC = () => {
  const { isAuthenticated, user } = useAuth()

  // Ensure the Frontegg auth is loaded
  if (!ContextHolder.getContext()) {
    return <div>Loading...</div>
  }

  useEffect(() => {
    const syncUserWithNoCoDB = async () => {
      if (isAuthenticated && user) {
        try {
          const { id: userId, name, email, tenantId, accessToken } = user;
          
          // Fetch tenant information from the tenants endpoint
          const tenantsResponse = await fetch('https://app-6yutcyqk6ant.us.frontegg.com/frontegg/identity/resources/users/v3/me/tenants', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          const tenantsData = await tenantsResponse.json();
          console.log('Tenants Response:', tenantsData);
          const companyName = tenantsData?.activeTenant?.name || 'Not Found';
          
          console.log('Final company name selected:', companyName);
          console.log('FrontEgg User Data:', { userId, name, email, tenantId, companyName });
          
          console.log('Attempting NoCoDB sync with:', {
            tenantId,
            userId,
            name: name || '',
            email,
            companyName
          });
          
          const result = await checkTenantAndUser(tenantId, userId, name || '', email, companyName);
          console.log('NoCoDB sync result:', result);
        } catch (error: any) {
          console.error('Error syncing user with NoCoDB:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
            stack: error.stack
          });
        }
      }
    };

    syncUserWithNoCoDB();
  }, [isAuthenticated, user]);

  // If the user is not authenticated, show the Login component
  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 overflow-auto">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/desktop" element={<Desktop />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/oauth/callback" element={<OAuthCallback />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  )
}

export default App