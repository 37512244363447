import React, { useState } from 'react';
import { Thermometer, ThumbsUp, ThumbsDown } from 'lucide-react';
import { useFeedbackSubmission } from '../hooks/useFeedbackSubmission';
import type { FeedbackFormState } from '../types/feedback';

interface SolutionRating {
  name: string;
  rating: number;
}

const features = [
  'Applications',
  'Knowledge',
  'Automations',
  'Status Page',
  'Migrations',
  'Ticketing',
  'Your Bot'
];

const DesktopFeedbackForm: React.FC = () => {
  const [solutionRatings, setSolutionRatings] = useState<SolutionRating[]>(
    features.map(feature => ({
      name: feature,
      rating: 5
    }))
  );
  const [addressesChallenges, setAddressesChallenges] = useState<boolean | null>(null);
  const [challengeDetails, setChallengeDetails] = useState('');
  const [challengesImportance, setChallengesImportance] = useState('');
  const [paymentConsideration, setPaymentConsideration] = useState<boolean | null>(null);
  const [competitorDetails, setCompetitorDetails] = useState('');
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const [bookFollowUp, setBookFollowUp] = useState<boolean | null>(null);

  const { isSubmitting, error, submitted, handleSubmit } = useFeedbackSubmission({
    solutionName: 'Desktop Application'
  });

  const handleRatingChange = (name: string, value: number) => {
    setSolutionRatings(prev =>
      prev.map(rating =>
        rating.name === name ? { ...rating, rating: value } : rating
      )
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Create a mapping of UI names to database column names
    const ratingMap = solutionRatings.reduce((acc, curr) => {
      const dbName = 
        curr.name === 'Your Bot' ? 'Bots' :
        curr.name === 'Status Page' ? 'Status_Page' :
        curr.name === 'Automations' ? 'Automation' :
        curr.name;
      // Set the same value for Troubleshoot as Applications
      if (curr.name === 'Applications') {
        acc['Troubleshoot'] = curr.rating;
      }
      acc[dbName] = curr.rating;
      return acc;
    }, {} as Record<string, number>);

    const formState: FeedbackFormState = {
      rating: 5, // Default rating since Desktop form doesn't use it
      Applications: ratingMap.Applications,
      Knowledge: ratingMap.Knowledge,
      Automation: ratingMap.Automation,
      Status_Page: ratingMap.Status_Page,
      Migrations: ratingMap.Migrations,
      Ticketing: ratingMap.Ticketing,
      Bots: ratingMap.Bots,
      addressesChallenges,
      challengeDetails,
      challengesImportance,
      paymentConsideration,
      competitorDetails,
      additionalFeedback: `Solution Ratings:\n${features.map(f => 
        `${f}: ${solutionRatings.find(r => r.name === f)?.rating}/10`
      ).join('\n')}\n\nAdditional Feedback:\n${additionalFeedback}`,
      bookFollowUp
    };

    await handleSubmit(formState);
  };

  const BubbleButton = ({ value, onClick, selected }: { value: boolean; onClick: () => void; selected: boolean }) => (
    <button
      type="button"
      onClick={onClick}
      className={`w-8 h-8 rounded-full flex items-center justify-center focus:outline-none transition-colors ${
        selected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
      }`}
    >
      {value ? <ThumbsUp size={16} /> : <ThumbsDown size={16} />}
    </button>
  );

  if (submitted) {
    return (
      <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
        <p className="font-bold">Thank you for your feedback!</p>
        <p>Your input helps us improve the Desktop Application.</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">
      <h3 className="text-2xl font-semibold mb-6 text-center">Desktop Application Feedback</h3>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      <form onSubmit={handleFormSubmit} className="space-y-6">
        <div>
          <h4 className="text-lg font-medium mb-4">Rate each Desktop Application feature:</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {solutionRatings.map((solution) => (
              <div key={solution.name} className="flex flex-col md:flex-row items-start md:items-center gap-2">
                <div className="w-full md:w-32 text-sm font-medium">{solution.name}</div>
                <div className="w-full flex items-center gap-2">
                  <Thermometer className="text-blue-500 w-4 h-4 flex-shrink-0" />
                  <input
                    type="range"
                    min="1"
                    max="10"
                    value={solution.rating}
                    onChange={(e) => handleRatingChange(solution.name, Number(e.target.value))}
                    className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <Thermometer className="text-red-500 w-4 h-4 flex-shrink-0" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 font-medium">Does it address any current challenges you face?</label>
          <div className="flex space-x-4">
            <BubbleButton 
              value={true} 
              onClick={() => setAddressesChallenges(true)} 
              selected={addressesChallenges === true} 
            />
            <BubbleButton 
              value={false} 
              onClick={() => setAddressesChallenges(false)} 
              selected={addressesChallenges === false} 
            />
          </div>
          {addressesChallenges === true && (
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium">What challenges does it address?</label>
              <textarea
                value={challengeDetails}
                onChange={(e) => setChallengeDetails(e.target.value)}
                className="w-full p-2 border rounded bg-gray-50 text-gray-900"
                rows={3}
              />
            </div>
          )}
        </div>

        <div>
          <label className="block mb-2 font-medium">How would you describe the importance of these challenges?</label>
          <select
            value={challengesImportance}
            onChange={(e) => setChallengesImportance(e.target.value)}
            className="w-full p-2 border rounded bg-gray-50 text-gray-900"
          >
            <option value="">Select an option</option>
            <option value="not_urgent">Not Urgent</option>
            <option value="researching">Researching</option>
            <option value="planning">Planning</option>
            <option value="budgeting">Budgeting</option>
            <option value="seeking_solutions">Seeking Solutions</option>
          </select>
        </div>

        <div>
          <label className="block mb-2 font-medium">Would you consider paying for this solution if available?</label>
          <div className="space-x-4">
            <button
              type="button"
              onClick={() => setPaymentConsideration(true)}
              className={`px-4 py-2 rounded-full ${
                paymentConsideration === true ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setPaymentConsideration(false)}
              className={`px-4 py-2 rounded-full ${
                paymentConsideration === false ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              No
            </button>
          </div>
          {paymentConsideration === true && (
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium">Which vendors with similar solutions are you considering?</label>
              <textarea
                value={competitorDetails}
                onChange={(e) => setCompetitorDetails(e.target.value)}
                className="w-full p-2 border rounded bg-gray-50 text-gray-900"
                rows={3}
                placeholder="Please list the vendors you're considering..."
              />
            </div>
          )}
        </div>

        <div>
          <label className="block mb-2 font-medium">Additional thoughts or suggestions:</label>
          <textarea
            value={additionalFeedback}
            onChange={(e) => setAdditionalFeedback(e.target.value)}
            className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            rows={4}
            placeholder="Share your thoughts about the Desktop Application..."
          ></textarea>
        </div>

        <div>
          <label className="block mb-2 font-medium">Would you like to book a follow-up call?</label>
          <div className="space-x-4">
            <button
              type="button"
              onClick={() => setBookFollowUp(true)}
              className={`px-4 py-2 rounded-full ${
                bookFollowUp === true ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setBookFollowUp(false)}
              className={`px-4 py-2 rounded-full ${
                bookFollowUp === false ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              No
            </button>
          </div>
        </div>

        {bookFollowUp === true && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
            <p>Great! Please book a time that works for you on <a href="https://calendly.com/fixfinder" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Calendly</a>, you can also hit 'Submit Feedback' now and we'll reach out to you later as a follow-up.</p>
          </div>
        )}

        {bookFollowUp === false && (
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
            <p>That's okay. Feel free to reach out to us anytime at <a href="mailto:research@fixfinder.com" className="text-blue-500 hover:underline">research@fixfinder.com</a></p>
          </div>
        )}

        <button 
          type="submit" 
          disabled={isSubmitting}
          className={`w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  );
};

export default DesktopFeedbackForm;
